import { MainWrapper } from "src/layouts/MainWrapper";
import gamblingCompanies from "src/data/gamblingCompanies.json";
import { SiteRow } from "src/components/SiteRow";
import styled from "styled-components";
import { InfoBox } from "src/layouts/InfoBox";
import gamblingCompaniesPage from "src/data/pages/gamblingCompanies.json";

const Section = styled("section")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GamblingCompanies = () => {
  const { h1, intro, title, metaDescription } = gamblingCompaniesPage;

  return (
    <MainWrapper title={title} metaDescription={metaDescription}>
      <InfoBox>
        <h1>{h1}</h1>
        <p>{intro}</p>
      </InfoBox>

      <Section>
        {gamblingCompanies.map((site) => (
          <SiteRow
            key={site.id}
            image={site.image}
            bakgroundColor={site.bakgroundColor}
            name={site.name}
            siteUrl={site.siteUrl}
            peaks={site.peaks}
            bonus={site.bonus}
          />
        ))}
      </Section>
    </MainWrapper>
  );
};

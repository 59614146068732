import styled from "styled-components";
import { MainWrapperProps } from "./types";
import { Head } from "src/components/Head";
import background from "src/assets/images/background.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Main = styled("main")`
  background-image: url("${background}");
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
`;

const Div = styled("div")`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr;
  padding: 0 1rem 1rem;
  max-width: 1280px;
  margin: 0 auto;
  grid-gap: 1rem;
  min-height: 100vh;
  background-color: var(--body-color);

  & > section {
    grid-column: 1 / -1;
  }

  /* Grey out when mobile nav is open */
  [data-is-open="true"] ~ & {
    filter: blur(0.25rem);
  }
`;

export const MainWrapper = ({
  children,
  metaDescription,
  title,
}: MainWrapperProps) => {
  const { pathname } = useLocation();

  // Scroll restoration on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Head title={title} metaDescription={metaDescription} />
      <Main>
        <Div>{children}</Div>
      </Main>
    </>
  );
};

import { images } from "src/assets/images/sites";
import styled from "styled-components";
import { SiteRowProps } from "./types";

const Article = styled("article")`
  background-color: var(--body-color);
  overflow: hidden;
  display: grid;
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
  grid-template-columns: 8rem 1fr;
  grid-template-rows: auto 1fr;

  &:nth-of-type(odd) {
    background-color: var(--senary-color);
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    grid-template-rows: 1fr;
    gap: 1rem;
    grid-template-columns: 10rem 1fr 1fr 2fr;
    padding: 1rem;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 14rem 1fr 1fr 2fr;
  }
`;

const ImageWrapper = styled("div")<{ bakgroundColor: string }>`
  align-self: center;
  width: 100%;

  background-color: ${(props) => props.bakgroundColor};

  @media (min-width: 768px) {
    margin: -1rem 0 -1rem -1rem;
    width: calc(100% + 1rem);
  }
`;

const Image = styled("img")`
  object-fit: cover;
  height: auto;
  width: 100%;
`;

const Column = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p {
    margin: 0;
  }
`;

const Row = styled("div")`
  display: flex;
  gap: 0.5rem;
  grid-column: 1 / -1;

  @media (min-width: 768px) {
    grid-column: unset;
    gap: 1rem;
  }
`;

const H2 = styled("h2")`
  margin: 0;
  font-size: 1rem;
`;

const H3 = styled("h3")`
  margin: 0;
  font-size: 1.25rem;
  color: var(--secondary-color);
`;

const Anchor = styled("a")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  color: var(--font-color-reverse);
  background-color: var(--secondary-color);

  &:first-of-type {
    background-color: var(--primary-color);
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const SiteRow = ({
  bakgroundColor,
  image,
  siteUrl,
  name,
  bonus,
}: SiteRowProps) => {
  console.log("bakgroundColor:", bakgroundColor);
  return (
    <Article>
      <ImageWrapper bakgroundColor={bakgroundColor}>
        <Image src={images[image]} alt={name} />
      </ImageWrapper>
      <Column>
        <H2>{name}</H2>
        <H3>{bonus.name}</H3>
        <p>Omsättningskrav: {bonus.wageringRequirements}</p>
        <p>Lägsta odds: {bonus.lowestOdds}</p>
      </Column>
      <Column />
      <Row>
        <Anchor href={siteUrl} target="_blank" rel="noopener noreferrer">
          Hämta bonus
        </Anchor>
        <Anchor href={siteUrl} target="_blank" rel="noopener noreferrer">
          Spela här
        </Anchor>
      </Row>
    </Article>
  );
};

import { InfoBox } from "src/layouts/InfoBox";
import { MainWrapper } from "src/layouts/MainWrapper";
import contact from "src/data/pages/contact.json";
import styled from "styled-components";

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  grid-column: 1 / -1;

  label {
    font-weight: bold;
    height: fit-content;
  }

  input,
  textarea {
    padding: 0.5rem;
    font-size: 1rem;
    height: auto;
    border: none;
    background-color: var(--senary-color);
    border-radius: 0.25rem;
  }

  textarea {
    resize: vertical;
  }

  button {
    padding: 1rem;
    font-size: 1rem;
    background-color: var(--primary-color);
    color: var(--font-color-reverse);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }

  @media (min-width: 768px) {
    grid-column-start: 3;
    grid-column-end: 10;
  }
`;

export const Contact = () => {
  const { h1, intro, title, metaDescription } = contact;

  return (
    <MainWrapper title={title} metaDescription={metaDescription}>
      <InfoBox>
        <h1>{h1}</h1>
        <p>{intro}</p>
      </InfoBox>
      <Form
        action="https://formsubmit.co/cd02d1e8715491294424caa32c4e118a"
        method="post"
      >
        <label htmlFor="name">Namn:</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">E-post:</label>
        <input type="email" id="email" name="email" required />

        <label htmlFor="message">Meddelande:</label>
        <textarea id="message" name="message" required rows={8} />

        <button type="submit">Skicka</button>
      </Form>
    </MainWrapper>
  );
};

import styled from "styled-components";
import { SiteProps } from "./types";

const Article = styled("article")`
  position: relative;
  background-color: var(--body-color);
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 100%;
    height: 300px;

    @media (min-width: 768px) {
      height: 200px;
    }
  }
`;

const Anchor = styled("a")`
  width: 100%;
  text-align: center;
  padding: 1rem;
  color: var(--font-color-reverse);

  &:hover {
    opacity: 0.8;
  }

  &:nth-of-type(1) {
    background-color: var(--primary-color);
  }
  &:nth-of-type(2) {
    background-color: var(--secondary-color);
  }
`;

const Image = styled("img")`
  width: 100%;
  object-fit: cover;
`;

export const SiteBox = ({
  image,
  siteUrl,
  bonusUrl,
  name,
  siteText,
  bonusText,
}: SiteProps) => {
  return (
    <Article>
      <Image src={image} alt={name} />
      <Anchor href={siteUrl} target="_blank" rel="noreferrer">
        {siteText}
      </Anchor>
      <Anchor href={bonusUrl} target="_blank" rel="noreferrer">
        {bonusText}
      </Anchor>
    </Article>
  );
};

import styled from "styled-components";
import { BetBoxProps } from "./types";
import { Link } from "react-router-dom";

const Article = styled("article")`
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

  h2 {
    border-bottom: 1px solid #000;
  }

  h2,
  p {
    grid-column: 1 / -1;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  color: var(--font-color-reverse);
  background-color: var(--secondary-color);
  border-radius: 5px;
  font-size: 1.125rem;
  font-weight: bold;

  &:first-of-type {
    background-color: var(--primary-color);
  }

  &:hover {
    opacity: 0.8;
  }
`;

const Anchor = styled("a")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  color: var(--font-color-reverse);
  background-color: var(--secondary-color);
  border-radius: 5px;
  font-size: 1.125rem;
  font-weight: bold;

  &:first-of-type {
    background-color: var(--primary-color);
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const BetBox = ({
  match,
  // event
  description,
  odds,
  betUrl,
  date,
  slug,
}: BetBoxProps) => {
  return (
    <Article>
      <h2>{match}</h2>
      <p>{description}</p>
      <p>Odds: {odds}</p>
      <p>Datum: {date}</p>
      <StyledLink to={`/speltips/${slug}`}>Läs mer</StyledLink>
      <Anchor href={betUrl} target="_blank" rel="noreferrer">
        Spela nu
      </Anchor>
    </Article>
  );
};

import styled from "styled-components";
import { MainWrapper } from "src/layouts/MainWrapper";
import { SiteBox } from "src/components/SiteBox";
import { images } from "src/assets/images/sites";
import gamblingCompanies from "src/data/gamblingCompanies.json";
import { InfoBox } from "src/layouts/InfoBox";
import { Link } from "react-router-dom";
import { BetBox } from "src/components/BetBox";
import bets from "src/data/bets.json";
import home from "src/data/pages/home.json";

const Section = styled("section")`
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: auto 1fr;

  @media (min-width: 768px) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const H2 = styled("h2")`
  grid-column: 1 / -1;
  text-align: center;
`;

const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: var(--tertiary-color);
  color: var(--font-color-reverse);
  border-radius: 5px;
  font-size: 1.125rem;
  font-weight: bold;
  grid-column: 1 / -1;

  &:hover {
    opacity: 0.8;
  }
`;

export const Home = () => {
  const { h1, intro, title, metaDescription } = home;
  return (
    <MainWrapper title={title} metaDescription={metaDescription}>
      <InfoBox>
        <h1>{h1}</h1>
        <p>{intro}</p>
      </InfoBox>

      <Section>
        <H2>Senaste spelbolagen</H2>
        {gamblingCompanies.slice(0, 3).map((site) => (
          <SiteBox
            key={site.id}
            image={images[site.image]}
            siteUrl={site.siteUrl}
            bonusUrl={site.bonusUrl}
            name={site.name}
            siteText={`Besök ${site.name}`}
            bonusText="Hämta Bonus"
          />
        ))}
      </Section>
      <StyledLink to="/spelbolag">Se alla spelbolag</StyledLink>

      <Section>
        <H2>Senaste speltipsen</H2>
        {bets.slice(0, 3).map((bet) => (
          <BetBox
            key={bet.id}
            match={bet.match}
            slug={bet.slug}
            event={bet.event}
            description={bet.description[0]}
            odds={bet.odds}
            betUrl={bet.betUrl}
            date={bet.date}
          />
        ))}
      </Section>
      <StyledLink to="/speltips">Se alla speltips</StyledLink>
    </MainWrapper>
  );
};

import betssonImage from "./betsson.jpg";
import leoVegasImage from "./leo-vegas.jpg";
import unibetImage from "./unibet.webp";
import ggBetImage from "./gg-bet.png";
import goldenbetImage from "./goldenbet.png";

export const images = {
  betssonImage,
  leoVegasImage,
  unibetImage,
  ggBetImage,
  goldenbetImage,
} as Record<string, string>;

import { BetRow } from "src/components/BetRow";
import bets from "src/data/bets.json";
import { InfoBox } from "src/layouts/InfoBox";
import { MainWrapper } from "src/layouts/MainWrapper";
import styled from "styled-components";
import bettingTips from "src/data/pages/bettingTips.json";

const Table = styled("table")`
  width: 100%;
`;

const Tr = styled("tr")`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  grid-gap: 1rem;
  padding: 1rem;
  width: 100%;
  background-color: #f0f0f0;
  border: 1px solid lightgrey;

  &:not(:last-of-type) {
    border-bottom: none;
  }

  &:hover {
    background-color: #e0e0e0;
  }

  h2 {
    font-size: 1rem;
  }
`;

export const BettingTips = () => {
  const { h1, intro, title, metaDescription } = bettingTips;

  return (
    <MainWrapper title={title} metaDescription={metaDescription}>
      <InfoBox>
        <h1>{h1}</h1>
        <p>{intro}</p>
      </InfoBox>

      <section>
        <h2>Senaste speltipsen</h2>
        <Table>
          <thead>
            <Tr>
              <th>Match/Event</th>
              <th>Odds</th>
              <th>Datum</th>
              <th>Spel</th>
            </Tr>
          </thead>
          <tbody>
            {bets.map((bet) => (
              <BetRow
                key={bet.id}
                slug={bet.slug}
                bet={bet.bet}
                match={bet.match}
                event={bet.event}
                odds={bet.odds}
                betUrl={bet.betUrl}
                date={bet.date}
              />
            ))}
          </tbody>
        </Table>
      </section>
    </MainWrapper>
  );
};

import { Header } from "../layouts/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  BettingTips,
  BettingTip,
  Contact,
  GamblingCompanies,
  NotFound,
  OddsBonuses,
  Home,
} from "../pages";
import { Footer } from "src/layouts/Footer";

export const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/speltips" element={<BettingTips />} />
        <Route path="/speltips/*" element={<BettingTip />} />
        <Route path="/oddsbonusar" element={<OddsBonuses />} />
        <Route path="/spelbolag" element={<GamblingCompanies />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

import styled from "styled-components";
import { InfoBoxProps } from "./types";

const Section = styled("section")`
  padding: 1rem 1rem 8rem;
  height: fit-content;
  color: var(--font-color-reverse);
  margin: 0 -1rem;

  /* Cool gradient background color (Not white) */
  background-image: linear-gradient(
    180deg,
    var(--tertiary-color) 0%,
    var(--tertiary-color) 40%,
    var(--body-color) 100%
  );

  @media (min-width: 768px) {
    padding: 5rem 1rem;
    background-image: linear-gradient(
      150deg,
      var(--tertiary-color) 0%,
      var(--tertiary-color) 40%,
      var(--body-color) 100%
    );

    & > div {
      max-width: 50%;
    }
  }
`;

export const InfoBox = ({ children }: InfoBoxProps) => {
  return (
    <Section>
      <div>{children}</div>
    </Section>
  );
};

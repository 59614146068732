import styled from "styled-components";
import { BetRowProps } from "./types";
import { Link } from "react-router-dom";

const Tr = styled("tr")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  grid-gap: 1rem;
  padding: 1rem;
  width: 100%;
  background-color: #f0f0f0;
  border: 1px solid lightgrey;

  &:not(:last-of-type) {
    border-bottom: none;
  }

  &:hover {
    background-color: #e0e0e0;
  }

  h2 {
    font-size: 1rem;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
  }
`;

const Td = styled("td")`
  text-align: center;

  &:first-of-type {
    text-align: left;
  }

  &:last-of-type {
    grid-column: span 2;
  }

  @media (min-width: 768px) {
    &:last-of-type {
      grid-column: span 1;
    }
  }
`;

const Anchor = styled("a")`
  background-color: var(--primary-color);
  color: var(--font-color-reverse);
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledLink = styled(Link)`
  background-color: var(--secondary-color);
  color: var(--font-color-reverse);
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s;
  margin-left: 0.5rem;

  &:hover {
    opacity: 0.8;
  }
`;

const Paragraph = styled("p")`
  margin: 0;
`;

const EventParagraph = styled(Paragraph)`
  font-size: 0.875rem;
  color: var(--quinary-color);
`;

export const BetRow = ({
  bet,
  event,
  match,
  odds,
  slug,
  betUrl,
  date,
}: BetRowProps) => {
  return (
    <Tr>
      <Td>
        <Paragraph>{match}</Paragraph>
        <EventParagraph>{event}</EventParagraph>
      </Td>
      <Td>{odds}</Td>
      <Td>{date}</Td>
      <Td>{bet}</Td>
      <Td>
        <Anchor href={betUrl} target="_blank" rel="noreferrer">
          Spela nu
        </Anchor>
        <StyledLink to={slug}>Läs mer</StyledLink>
      </Td>
    </Tr>
  );
};

import { MainWrapper } from "src/layouts/MainWrapper";
import bets from "src/data/bets.json";
import { useLocation } from "react-router-dom";
import { NotFound } from "./NotFound";
import styled from "styled-components";
import gamblingCompanies from "src/data/gamblingCompanies.json";
import { images } from "src/assets/images/sites";

const Section = styled("section")`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  @media (min-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 8 !important;
  }
`;

const HeadingWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--quaternary-color);
  color: var(--font-color-reverse);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 0.5rem 1rem;
`;

const Pre = styled("pre")`
  margin: 0;
  padding: 1rem;
  color: var(--quinary-color);
  border-bottom: 1px solid var(--senary-color);
`;

const Summary = styled("p")`
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  margin: 2rem;
  align-self: center;
  justify-self: center;
`;

const Aside = styled("aside")`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  max-height: fit-content;
  padding: 1rem;
  background-color: var(--senary-color);

  h2 {
    font-size: 1.5rem;
    margin: 0;
  }

  @media (min-width: 768px) {
    grid-column-start: 8;
    grid-column-end: 13;
    grid-row: 1 / -1;
    position: sticky;
    top: 5rem;
    margin-top: 1rem;
  }
`;

const Anchor = styled("a")`
  background-color: var(--primary-color);
  color: var(--font-color-reverse);
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s;
  font-size: 1.25rem;

  &:hover {
    opacity: 0.8;
  }
`;

const List = styled("ul")`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const AsideBottomWrapper = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;

  h3 {
    grid-column: span 2;
    text-align: center;
    font-size: 1.5rem;
  }
`;

const Image = styled("img")`
  width: 100%;
  height: auto;
  border-radius: 0.25rem;
`;

const StatusHeadingWrapper = styled("div")`
  padding: 1rem 0.5rem;
  border-radius: 0.25rem;
  &[data-bet-status="win"] {
    color: var(--font-color-reverse);
    background-color: #4caf50;
  }

  h3 {
    margin: 0;
    text-align: center;
    font-size: 1.5rem;
  }
`;

export const BettingTip = () => {
  const { pathname } = useLocation();
  const slug = pathname.split("/").pop();
  const bet = bets.find((bet) => bet.slug === slug);

  if (!bet) {
    return <NotFound />;
  }

  const gamblingCompany = gamblingCompanies.find(
    (company) => company.slug === bet?.betCompany
  );

  return (
    <MainWrapper
      title={`Proffstips - ${bet.match}`}
      metaDescription={bet.description[0]}
    >
      <Section>
        <HeadingWrapper>
          <h1>
            Speltips: {bet.match} - {bet.date}
          </h1>
        </HeadingWrapper>
        <Pre>Skapad: {bet.createdAt}</Pre>
        <Summary>{bet.summary}</Summary>
      </Section>
      <Aside>
        <h2>Spelinformation</h2>
        {/* <Image src={images[bet.image]} alt={bet.match} /> */}
        <List>
          <li>
            <strong>Match</strong>: {bet.match}
          </li>
          <li>
            <strong>Liga/Event</strong>: {bet.event}
          </li>
          <li>
            <strong>Speltid</strong>: {bet.date}
          </li>
          <li>
            <strong>Odds</strong>: {bet.odds}
          </li>
          <li>
            <strong>Spel</strong>: {bet.bet}
          </li>
        </List>
        <Anchor href={bet.betUrl}>Spela nu</Anchor>
        <AsideBottomWrapper>
          {gamblingCompany?.image && (
            <Image
              src={images[gamblingCompany.image]}
              alt={gamblingCompany?.name}
            />
          )}
          <h3>Odds: {bet.odds}</h3>
        </AsideBottomWrapper>
        {/* Status */}
        <StatusHeadingWrapper data-bet-status={bet.status}>
          <h3>{bet.statusText}</h3>
        </StatusHeadingWrapper>
      </Aside>
      <Section>
        {bet.description.map((paragraph, index) => (
          <p key={paragraph}>{paragraph}</p>
        ))}
      </Section>
    </MainWrapper>
  );
};
